import {useEffect, useState} from "react"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import CollapseSubMenuItems from "./CollapseSubMenuItems"
import {NavLink} from "react-router-dom"
import styles from "./SideMenu.module.scss"
import Typography from "@mui/material/Typography"
import List from "@mui/material/List"
import {source} from "../../constants/imageUrls"

type MenuItemsProps = {
    icon?: string
    name?: string
    child?: any
    open?: boolean
    path?: any
    active?: any
    isHover?: any
    parentActiveMenu: boolean
    setParentActiveMenu?: any
}

const MenuItems = (props: MenuItemsProps) => {
    const [showChild, setShowChild] = useState(false)

    useEffect(() => {
        props.child && setShowChild(props.parentActiveMenu)
    }, [props.parentActiveMenu])

    const enabledNav = () => {
        return (
            <NavLink to={props.path} style={{textDecoration: "none", color: "unset"}}>
                <ListItemButton
                    sx={
                        props.open || props.isHover
                            ? {
                                  minHeight: 48,
                                  justifyContent: "initial",
                                  px: 3,
                              }
                            : {
                                  minHeight: 48,
                                  justifyContent: "center",
                                  padding: 0,
                              }
                    }
                    selected={props.path === props.active ? true : false}
                    className={styles.parent_nav}
                    onClick={() => props.setParentActiveMenu(false)}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 1,
                            justifyContent: "center",
                            margin: 0,
                            padding: {
                                desktop_sm: 0,
                                desktop_md: 0,
                                desktop_lg: 0,
                            },
                            // width: "25px",
                            width: {
                                desktop_sm: 23,
                                desktop_md: 30,
                                desktop_lg: 32,
                            },
                            height: {
                                desktop_sm: 23,
                                desktop_md: 30,
                                desktop_lg: 32,
                            },
                        }}
                        className={props.path === props.active ? styles.nav_buttons_icons : ""}
                    >
                        <img src={props.icon} />
                    </ListItemIcon>
                    {(props.open || props.isHover) && (
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2">{props.name}</Typography>}
                            sx={{width: "calc(100% - 25px)", marginLeft: "4px"}}
                            className={props.path === props.active ? styles.nav_buttons__active : styles.nav_buttons}
                        />
                    )}
                </ListItemButton>
            </NavLink>
        )
    }

    const hasChild = () => {
        return (
            <ListItemButton
                sx={
                    props.open || props.isHover
                        ? {
                              minHeight: 48,
                              justifyContent: "initial",
                              color: "#101941",
                              fontWeight: 600,
                              px: 3,
                              paddingRight: "0",
                          }
                        : {
                              minHeight: 48,
                              justifyContent: "center",
                              color: "#101941",
                              fontWeight: 600,
                              padding: 0,
                          }
                }
                className={
                    props.active.includes(props.path) && props.child
                        ? styles.parent_nav_submenu__active
                        : styles.parent_nav_submenu
                }
                selected={!props.isHover && props.active.includes(props.path)}
                onClick={() => {
                    // setShowChild(!showChild)
                    props.setParentActiveMenu(!props.parentActiveMenu)
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: "center",
                        margin: 0,
                        // width: "25px",
                        width: {
                            desktop_sm: 23,
                            desktop_md: 30,
                            desktop_lg: 32,
                        },
                        height: {
                            desktop_sm: 23,
                            desktop_md: 30,
                            desktop_lg: 32,
                        },
                    }}
                >
                    <img src={props.icon} />
                </ListItemIcon>
                {(props.open || props.isHover) && (
                    <ListItemText
                        disableTypography
                        primary={<Typography variant="body2">{props.name}</Typography>}
                        sx={{width: "calc(100% - 40px)", marginLeft: "4px"}}
                    />
                )}
                {(props.open || props.isHover) && (
                    <ListItemIcon
                        sx={{
                            transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
                            transform: showChild ? "rotateX(180deg)" : "rotateX(0deg)",
                            minWidth: 0,
                            justifyContent: "center",
                            marginRight: "10px",
                        }}
                    >
                        {<img src={source.menu.ExpandMoreIcon} alt="expand" />}
                    </ListItemIcon>
                )}
            </ListItemButton>
        )
    }

    return (
        <ListItem
            disablePadding
            sx={{display: "block"}}
            onClick={() => (props.child && showChild ? setShowChild(true) : setShowChild(!showChild))}
        >
            {props.child ? hasChild() : enabledNav()}
            <List>
                {props.child &&
                    showChild &&
                    (props.isHover || props.open) &&
                    props.child.map((collpase: any) => (
                        <CollapseSubMenuItems
                            key={collpase.path}
                            icon={collpase.icon}
                            name={props.open || props.isHover ? collpase.name : ""}
                            path={collpase.path}
                            submenu_path={collpase.submenu_path}
                            isActive={
                                collpase.submenu_path === (props.active || props.isHover) ||
                                collpase.path === (props.active || props.isHover)
                                    ? true
                                    : false
                            }
                        />
                    ))}
            </List>
        </ListItem>
    )
}

export default MenuItems
