import {lazy, useEffect, Suspense} from "react"
import {useDispatch} from "react-redux"
import {createBrowserRouter, RouterProvider} from "react-router-dom"
import ErrorBoundary from "./ErrorBoundary/index"
import Toaster from "./components/Toaster"
import Landing from "./layout/Landing"
import {setCredentials} from "./pages/Login/authSlice"
import RequiredAuth from "./routes/RequiredAuth"
// import {init as initApm} from "@elastic/apm-rum"

function App() {
    const CompanyManagement = lazy(() => import("./pages/CompanyManagement"))
    const Profile = lazy(() => import("./pages/CompanyManagement/features/Profile"))
    const Network = lazy(() => import("./pages/NetworkDetails"))
    const Admin = lazy(() => import("./pages/CompanyManagement/features/Admin"))
    const Registration = lazy(() => import("./pages/CompanyManagement/features/Registration"))
    const RegistrationDetails = lazy(
        () => import("./pages/CompanyManagement/features/Registration/Features/RegistrationDetails")
    )
    const Mapping = lazy(() => import("./pages/CompanyManagement/features/Mapping"))
    const Settlements = lazy(() => import("./pages/CompanyManagement/features/Settlements"))
    const Locations = lazy(() => import("./pages/Locations"))
    const Tariff = lazy(() => import("./pages/Tariff"))
    const Tokens = lazy(() => import("./pages/Tokens"))
    const Session = lazy(() => import("./pages/Sessions"))
    const Logs = lazy(() => import("./pages/Logs"))
    const UserProfile = lazy(() => import("./pages/UserProfile"))
    const Complaints = lazy(() => import("./pages/Complaints"))

    // const apm = initApm({
    //     // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    //     serviceName: "hub-ui",
    //     // Set custom APM Server URL (default: http://localhost:8200)
    //     serverUrl: process.env.REACT_APP_APM_KEY,
    //     // Set the service version (required for source map feature)
    //     serviceVersion: "",
    //     // Set the service environment
    //     environment: process.env.REACT_APP_APM !== "local" ? process.env.REACT_APP_APM : "local",
    //     active: process.env.REACT_APP_APM === "local" || process.env.REACT_APP_APM === "production" ? false : true,
    //     disableInstrumentations: ["page-load", "history", "eventtarget", "click"],
    // })

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setCredentials({token: ""}))
    }, [])

    const router = createBrowserRouter([
        {
            element: <Landing />,
            errorElement: <ErrorBoundary />,
            path: "/auth",
        },

        {
            element: <RequiredAuth />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: "company-management",
                    errorElement: <ErrorBoundary />,
                    element: <CompanyManagement />,
                    children: [
                        {
                            path: "profile",
                            errorElement: <ErrorBoundary />,
                            element: <Profile />,
                        },
                        {
                            path: "admin",
                            errorElement: <ErrorBoundary />,
                            element: <Admin />,
                        },
                        {
                            path: "registration",
                            errorElement: <ErrorBoundary />,
                            element: <Registration />,
                        },
                        {
                            path: "registration/details",
                            errorElement: <ErrorBoundary />,
                            element: <RegistrationDetails />,
                        },
                        {
                            path: "mapping",
                            errorElement: <ErrorBoundary />,
                            element: <Mapping />,
                        },
                        {
                            path: "settlements",
                            errorElement: <ErrorBoundary />,
                            element: <Settlements />,
                        },
                    ],
                },
                {
                    path: "/",
                    errorElement: <ErrorBoundary />,
                    element: <Profile />,
                },
                {
                    path: "network",
                    errorElement: <ErrorBoundary />,
                    element: <Network />,
                },
                {
                    path: "locations",
                    errorElement: <ErrorBoundary />,
                    element: <Locations />,
                },
                {
                    path: "tariff",
                    errorElement: <ErrorBoundary />,
                    element: <Tariff />,
                },
                {
                    path: "tokens",
                    errorElement: <ErrorBoundary />,
                    element: <Tokens />,
                },
                {
                    path: "sessions",
                    errorElement: <ErrorBoundary />,
                    element: <Session />,
                },
                {
                    path: "logs",
                    errorElement: <ErrorBoundary />,
                    element: <Logs />,
                },
                {
                    path: "user-profile",
                    element: <UserProfile />,
                },
                {
                    path: "complaints",
                    errorElement: <ErrorBoundary />,
                    element: <Complaints />,
                },
                // {
                //     path: "*",
                //     element: <ErrorBoundary />,
                // },
            ],
        },
    ])

    return (
        <Suspense fallback={<>Loading...</>}>
            <Toaster vertical={"top"} horizontal={"center"} autoHideDuration={3000} />
            <RouterProvider router={router} />
        </Suspense>
    )
}

export default App
