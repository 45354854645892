import {CircularProgress} from "@mui/material"
import Button from "@mui/material/Button"

type Props = {
    [key: string]: any
}

const LoadingButton = (props: Props) => {
    return (
        <Button {...props}>
            {props.loading ? (
                <CircularProgress
                    sx={props?.colour !== undefined ? {color: `${props.colour}`} : {color: "#fff"}}
                    size="1.5rem"
                />
            ) : (
                props.children
            )}
        </Button>
    )
}
export default LoadingButton
