import React, {useEffect, useState} from "react"
import {useFormik} from "formik"
import {useGetProfileDataQuery, useLogoutApiMutation} from "./Services/userService"
import {setUserDetails} from "../CompanyManagement/features/Profile/userSlice"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom"
import {iconSource} from "../../constants/iconUrls"

export type ModalProps = {
    open: boolean
    onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const useUserProfile = () => {
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLInputElement) | null>(null)
    const {data: profileData = [], isLoading: profileLoading = false} = useGetProfileDataQuery({})
    const [logoutApi, {isLoading: logoutIsLoading}] = useLogoutApiMutation({})

    const [openNameModal, setOpenNameModal] = useState(false)
    const [openEmailModal, setOpenEmailModal] = useState(false)
    const [openPhoneModal, setOpenPhoneModal] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        Object.keys(profileData).length !== 0 && dispatch(setUserDetails({user_details: {profileData}}))
    }, [profileData])

    const formik = useFormik({
        initialValues: {
            name: profileData?.name || "",
            phone: profileData?.phone || "",
            email: profileData?.email || "",
            company_name: profileData?.company_name || "",
            company_type: profileData?.company_role || "",
        },
        enableReinitialize: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {},
    })

    const formData = {
        fields: [
            {
                type: "row",
                style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "24px",
                    gap: "24px",
                },
                fields: [
                    {
                        subChild: true,
                        type: "text",
                        label: "User Name",
                        name: "name",
                        placeholder: "Enter Name",
                        icon: iconSource.icons.EditAltIcon,
                        disabled: true,
                        editIcon: true,
                        iconClickHandler: () => setOpenNameModal(true),
                        style: {
                            width: "340px",
                        },
                    },
                    {
                        subChild: true,
                        type: "number",
                        label: "Mobile Number",
                        name: "phone",
                        placeholder: "Enter Number",
                        icon: iconSource.icons.EditAltIcon,
                        editIcon: true,
                        iconClickHandler: () => setOpenPhoneModal(true),
                        disabled: true,
                        style: {
                            width: "340px",
                        },
                    },
                ],
            },

            {
                type: "row",
                style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "24px",
                    gap: "24px",
                },
                fields: [
                    {
                        subChild: true,
                        type: "email",
                        label: "Email ID",
                        name: "email",
                        placeholder: "Enter Email Id",
                        icon: iconSource.icons.EditAltIcon,
                        editIcon: true,
                        iconClickHandler: () => setOpenEmailModal(true),
                        disabled: true,
                        style: {
                            width: "340px",
                        },
                    },
                    {
                        subChild: true,
                        type: "text",
                        label: "Company Name",
                        name: "company_name",
                        placeholder: "Enter Company Name",
                        disabled: true,
                        style: {
                            width: "340px",
                        },
                    },
                ],
            },
            {
                type: "row",
                style: {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "24px",
                    gap: "24px",
                },
                fields: [
                    {
                        subChild: true,
                        type: "text",
                        label: "Company Type",
                        name: "company_type",
                        placeholder: "Enter Company Type",
                        disabled: true,
                        style: {
                            width: "340px",
                        },
                    },
                    {
                        style: {
                            width: "340px",
                            visibility: "hidden",
                            userSelect: "none",
                        },
                    },
                ],
            },
        ],
    }

    const handleLogout = () => {
        logoutApi("").then((response: any) => {
            if (response?.data.meta.status_code === 200) {
                localStorage.clear()
                navigate("/")
            }
        })
    }

    return {
        states: {
            anchorEl,
            profileData,
            formik,
            formData,
            openNameModal,
            openEmailModal,
            openPhoneModal,
            profileLoading,
            logoutIsLoading,
        },
        functions: {handleClick, handleClose, setOpenNameModal, setOpenEmailModal, setOpenPhoneModal, handleLogout},
    }
}

export default useUserProfile
